.fade.HealthManagement.modal.show{
    padding-right: 0px !important;
}

#wpnb1 .modal-body .tab-content {
    width: 100%;
    text-align: left;
    border-left: 0px solid #e9e9e9 !important;
    border-bottom: 0px solid #e9e9e9 !important;
    border-right: 0px solid #e9e9e9 !important;
    padding: 0px !important;
}

#wpnb1 .modal-content {
    border-radius: 20px;
    border: 0px solid #e9e9e9 !important;;
}

#wpnb1 .nav-link {
    color: #333 !important;
    font-weight: 500;
    border: 0;
}

#wpnb1 .nav-link.active {
    color: #0469FF !important;
    font-weight: 500;
    border: 0;
    border-bottom: 3px solid #0469FF !important;
}

.addRow-btn{
    color: #fff!important;
    background: #37C504 !important;
    border: 1px solid #37C504 !important;
    font-size: 15px !important;
    padding: 4px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
  }
  
.addRow-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be!important;
  }

  .clearRow-btn{
    color: #fff!important;
    background: #8E8E8E!important;
    border: 1px solid #8E8E8E!important;
    font-size: 15px !important;
    padding: 4px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
  }
  
.clearRow-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be!important;
  }