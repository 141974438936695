.CorporatesEmployeesOnboarded .bg-grey {
    background-color: #f0f4ff;
    border-radius: 14px;
    border: none;
}

.CorporatesEmployeesOnboarded .card-icon {
    height: 75px;
    width: 75px;
}

.CorporatesEmployeesOnboarded .card-title {
    color: #333;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: .3rem;
    text-align: left;
}

.CorporatesEmployeesOnboarded .card-text {
    color: #636773;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    text-align: left;
}