@media print {
  @page {
    size: A4;
    margin: 0;
  }

  body {
    width: 210mm;
    height: 297mm;
    margin: 0;
    font-family: sans-serif;
  }
}
    .container {
      position: relative;
    }
    .background_image {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      z-index: 0;
      width: 100%;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
    }
    .left_column {
      width: 50%;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
    }
    .logo {
      /* margin: 32px 0; */
      width: 50%;
      margin-bottom: 30px;
    }
    .title {
      font-size: 1.875rem;
      margin: 0;
    }
    .title_2 {
      font-size: 2.5rem;
      margin: 0;
      font-weight: 900;
    }
    .orange_text {
      color: #f97316;
    }
    .teal_text {
      color: #0a9aa2;
      font-weight: bold;
    }
    .subtitle {
      font-size: 1.6rem;
      margin: 0;
      color: #6b7280;
    }
    .description {
      margin: 0;
      font-size: 1.125rem;
      color: #6b7280;
      line-height: 1.25;
      width: 80%;
    }
    .right_column {
      width: 50%;
    }
    .right_column img {
      width: 100%;
      object-fit: cover;
    }
    .patient_info {
      position: relative;
      padding-top: 96px;
      padding-bottom: 96px;
      margin-top: 260px;
    }
    .patient_details {
      padding: 32px;
    }
    .patient_id {
      color: #6b7280;
      font-size: 1.5rem;
      margin: 0;
    }
    .patient_name {
      color: #374151;
      font-weight: bold;
      font-size: 2.25rem;
      margin: 0;
    }
    .patient_meta {
      color: #6b7280;
      font-size: 1.5rem;
      margin: 0 2px;
    }
    .report_dates {
      padding: 16px 32px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      background-color: #fd9a70;
      color: white;
    }
    .report_dates div {
      margin-right: 24px;
    }
    .report_dates h6 {
      margin: 0;
      font-size: 0.875rem;
    }
    .report_dates h4 {
      margin: 0;
      font-size: 1rem;
      font-weight: bold;
    }