.notificationDiv {

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    background-color: #fff;
    padding: 14px;
    border-radius: 22px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

    .notificationTitle {
        font-size: 18px;
        color: rgb(51, 51, 51);
        font-weight: 500;
    }

    .fa-bell {
        font-size: 42px;
        color: #FEB558
    }

    .bellContainer {
        /*margin-right: 20px;*/
    }
}

.notification-btn-closs {
    background-color: #fff;
    padding: 3px 7px;
    color: #03335b;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #03335b;
}
.notification-btn-closs:hover {
    background-color: #1279be;
    color: #fff;
    border: 1px solid #1279be;
}