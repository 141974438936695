.CorporateDashboard{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.CorporateDashboard .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}


.corporate-dashboard{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}


.corporate-dashboard .dashboard-title {
    font-size: 22px;
    color: #1D1C1C;
    font-weight: 600;
    margin-bottom: 0px;
}

.corporate-dashboard .dashboard-subtitle {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.corporate-dashboard .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.corporate-dashboard .sub-title-1 {
    color: #747474;
    font-size: 16px;
}

.dashboard-corpwell-title {
    align-items: flex-start;
    color: #636773;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.dashboard-corpwell-subtitle {
    color: #636773;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}
.dashboard-corpwell-subtitle span {
    color: #333 !important;
    font-weight: 600;
}
.active-corpwell-img {
    height: 54px;
}

.boxed-number {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 82px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    height: 180px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle !important;
}