/* src/Timeline.css */
#container-timeline {
    width: 70%; 
    margin: auto;
}

ol.timeline-profile {
    border-left: 7px solid;
    border-image: linear-gradient(190deg, rgba(188, 215, 150, 1) 0%, rgba(188, 215, 150, 1) 90%, rgba(255, 255, 255, 1) 100%) 1;
    padding-left: 2em;
    margin-left: 15em;
    list-style: none;
}

.timeline-profile > li {
    position: relative;
    margin-top: 10pt;
    color: white;
}

.timeline-profile > li:before {
    background-color: #82B63A;
    text-align: center;
    width: 2em; 
    height: 2em;
    line-height: 2em;
    font-size: 110%;
    border: 0.5em solid #BCD796;
    border-radius: 50%;
    position: absolute;
    left: -3.1em;
}

.timeline-profile > li.call:before {
    content: "";
}

.timeline-profile > li time {
    display: block;
    float: left;
    position: absolute;
    left: -20em;
    text-align: right;
    width: 240px;
}

.timeline-profile > li time > * {
    display: block;
}

.timeline-profile > li time small {
    color: #7D7D7D;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
}

.timeline-profile > li time .big-profile {
    color: #7D7D7D;
    font-size: 14px;
    line-height: 1.2;
}

.timeline-profile > li article {
    background-color: #fff;
    margin: 0; 
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.timeline-profile > li article:after {
    content: "\25C0";
    color: #fff;
    position: absolute;
    top: 0.5em; 
    left: -0.6em;
}

.timeline-profile > li article h3 {
    padding-bottom: 5pt;
    border-bottom: 1pt dotted;
    margin-bottom: 10pt;
    color: #7D7D7D;
}

.timeline-profile > li article p {
    color: #7D7D7D;
    line-height: 1.3;
}
