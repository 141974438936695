.ManageCorporates{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.ManageCorporates .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}


.manage-corporates{
    background-color: #fff;
    /* border-radius: 14px;
    box-shadow: 0 3px 12px #00000017; */
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}


.manage-corporates .dashboard-title {
    font-size: 22px;
    color: #1D1C1C;
    font-weight: 600;
    margin-bottom: 0px;
}

.manage-corporates .dashboard-subtitle {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.manage-corporates .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.manage-corporates .sub-title-1 {
    color: #747474;
    font-size: 16px;
}
