.InsurePlus{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.InsurePlus .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}


.insure-plus{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}


.insure-plus .dashboard-title {
    font-size: 22px;
    color: #1D1C1C;
    font-weight: 600;
    margin-bottom: 0px;
}

.insure-plus .dashboard-subtitle {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.insure-plus .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.insure-plus .sub-title-1 {
    color: #747474;
    font-size: 16px;
}

.dashboard-corpwell-title {
    align-items: flex-start;
    color: #636773;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.dashboard-corpwell-subtitle {
    color: #636773;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}
.dashboard-corpwell-subtitle span {
    color: #333 !important;
    font-weight: 600;
}
.active-corpwell-img {
    height: 54px;
}

.boxed-number {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 82px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    height: 180px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle !important;
}




.eFEHrh div:first-child{
    white-space: normal !important;
}

.tableEmployeePolicy .gdBOih:nth-child(1), .tableEmployeePolicy .dBiUCQ:nth-child(1){
    min-width: 240px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(2), .tableEmployeePolicy .dBiUCQ:nth-child(2){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(3), .tableEmployeePolicy .dBiUCQ:nth-child(3){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(4), .tableEmployeePolicy .dBiUCQ:nth-child(4){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(5), .tableEmployeePolicy .dBiUCQ:nth-child(5){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(6), .tableEmployeePolicy .dBiUCQ:nth-child(6){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(7), .tableEmployeePolicy .dBiUCQ:nth-child(7){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(8), .tableEmployeePolicy .dBiUCQ:nth-child(8){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(9), .tableEmployeePolicy .dBiUCQ:nth-child(9){
    min-width: 160px !important;
}

.tableEmployeePolicy .gdBOih:nth-child(10), .tableEmployeePolicy .dBiUCQ:nth-child(10){
    min-width: 160px !important;
}

.leaderboardEmployee-img{
    width: 46px;
    height: 46px;
    border-radius: 50px;
    margin-right: 0.5rem;
    border: 1px solid #e9e9e9;
    object-fit: cover;
}