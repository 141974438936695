
.PWADiabetic{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.PWADiabetic .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.PWA-Diabetic{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 10px;
    width: 100%;
}
.PWA-Diabetic .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.PWA-Diabetic .bg-grey {
    background-color: #f0f4ff;
}
.PWA-Diabetic .card-title1 {
    color: #333;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: .3rem;
    text-align: left;
}
.PWA-Diabetic .card-text1 {
    color: #636773;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
}

.PWA-Diabetic .windows{
    display: flex;
    overflow-x: auto;
    min-width: 100%;
    min-height: 70vh;
}

.PWA-Diabetic .windows .iphone{
    background-image:url('https://appcdn.goqii.com/storeimg/48647_1730799835.png');
    width:360px;
    height:669px;
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
}

.PWA-Diabetic .windows .border{
    position: absolute;
    top: 7%;
    right: 2%;
    left: 4%;
    bottom: 10%;
    overflow-x: hidden;
    border: 0 !important;
    overflow-y: scroll;
}

.PWA-Diabetic .windows .quate-bg{
    background-color: #f0f6fd;
    border-radius: 10px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.PWA-Diabetic .windows .iframe-bg {
    width: 100%;
    height: 100%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    margin: 0px auto;
}

.iframe-bg .btn-enrollNow {
    font-size: calc(12px + 1vw) !important;
}