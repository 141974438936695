.observation-dashboard-page .invalid-input {
    height: 10px; 
    font-size: 13px; 
    color: red; 
}   


.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    border: 1px solid #ddd; /* Add border to cells */
}

.styled-table thead tr {
    background-color: #f2f2f2; /* Light gray background for header */
    color: #333; /* Darker text color for readability */
}

.styled-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light background for even rows */
}

.styled-table tbody tr:hover {
    background-color: #e2e2e2; /* Highlight row on hover */
}

.td-styled-ob{
    cursor: default;
    background-color: rgb(212, 230, 239) !important;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    vertical-align: middle;
    padding: 4px 20px 4px 3px;
}
.td-styled-ob-text{
white-space: normal;
vertical-align: middle;
font-size: 13px;}


.comment {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
  
  .comment-heading {
    margin-bottom: 5px;
    margin-top: 0;
  }
  
  .comment-overview {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 20px 0;
  }
  
  .comment-overview p {
    margin-top: 0;
  }
  
  .comment-callout {
    color: #666;
    display: inline-block;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 0;
  }
  
  .comment-callout a {
    color: rgba(0, 188, 235, 1);
  }
  
  .comment-sorter {
    display: inline-block;
    float: right;
  }
  
  .comment-sorter .dropdown-toggle {
    color: #666;
    font-weight: 300;
    font-size: 13px;
  }
  
  [dir="rtl"] .comment-sorter {
    float: left;
  }
  
  .comment-wrapper {
    display: flex;
    position: relative;
  }
  
  .comment-wrapper.comment-official {
    border: 1px solid rgba(0, 188, 235, 1);
    padding: 40px 20px 20px;
  }
  
  @media (min-width: 768px) {
    .comment-wrapper.comment-official {
      padding-top: 20px;
    }
  }
  
  .comment-info {
    min-width: 0;
    padding-right: 20px;
    width: 100%;
  }
  
  [dir="rtl"] .comment-info {
    padding-right: 0;
    padding-left: 20px;
  }
  
  .comment-author {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .comment-author {
      justify-content: space-between;
    }
  }
  
  .comment-avatar {
    margin-right: 10px;
  }
  
  [dir="rtl"] .comment-avatar {
    margin-left: 10px;
    margin-right: 0;
  }
  
  .comment-meta {
    flex: 1 0 auto;
  }
  
  .comment-labels {
    flex-basis: 100%;
  }
  
  @media (min-width: 768px) {
    .comment-labels {
      flex-basis: auto;
    }
  }
  
  .comment .status-label:not(.status-label-official) {
    margin-top: 10px;
  }
  
  @media (min-width: 768px) {
    .comment .status-label:not(.status-label-official) {
      margin-top: 0;
    }
  }
  
  .comment-form {
    display: flex;
    padding-top: 30px;
    word-wrap: break-word;
  }
  
  .comment-container {
    width: 100%;
  }
  
  .comment-form-controls {
    display: none;
    margin-top: 10px;
    text-align: left;
  }
  
  @media (min-width: 768px) {
    [dir="ltr"] .comment-form-controls {
      text-align: right;
    }
  }
  
  .comment-form-controls input[type="submit"] {
    margin-top: 15px;
  }
  
  @media (min-width: 1024px) {
    .comment-form-controls input[type="submit"] {
      margin-left: 15px;
    }
    [dir="rtl"] .comment-form-controls input[type="submit"] {
      margin-left: 0;
      margin-right: 15px;
    }
  }
  
  .comment-form-controls input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .comment-form-controls input[type="checkbox"] [dir="rtl"] {
    margin-left: 5px;
  }
  
  .comment-ccs {
    display: none;
  }
  
  .comment-ccs + textarea {
    margin-top: 10px;
  }
  
  .comment-attachments {
    margin-top: 10px;
  }
  
  .comment-attachments a {
    color: rgba(0, 188, 235, 1);
  }
  
  .comment-body {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    line-height: 1.6;
    overflow-x: auto;
  }
  
  .comment-body img {
    height: auto;
    max-width: 100%;
  }
  
  .comment-body ul, .comment-body ol {
    padding-left: 20px;
    list-style-position: outside;
    margin: 20px 0 20px 20px;
  }
  
  [dir="rtl"] .comment-body ul, [dir="rtl"] .comment-body ol {
    padding-right: 20px;
    padding-left: 0;
    margin-left: 0;
    margin-right: 20px;
  }
  
  .comment-body ul > ul, .comment-body ol > ol, .comment-body ol > ul, .comment-body ul > ol, .comment-body li > ul, .comment-body li > ol {
    margin: 0;
  }
  
  .comment-body ul {
    list-style-type: disc;
  }
  
  .comment-body a:visited {
    color: #004189;
  }
  
  .comment-body code {
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0 2px;
  }
  
  .comment-body pre {
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 15px;
    overflow: auto;
    white-space: pre;
  }
  
  .comment-body blockquote {
    border-left: 1px solid #ddd;
    color: #666;
    font-style: italic;
    padding: 0 15px;
  }
  
  .comment-mark-as-solved {
    display: inline-block;
  }

  .user-avatar {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  
  header .nav-wrapper .user-avatar {
    display: none;
  }
  
  .avatar {
    display: inline-block;
    position: relative;
  }
  
  .avatar img {
    height: 40px;
    width: 40px;
  }
  
  .avatar .icon-agent::before {
    background-color: rgba(0, 188, 235, 1);
    border: 2px solid #fff;
    border-radius: 50%;
    bottom: -4px;
    color: rgba(153, 153, 153, 1);
    content: "\1F464";
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    right: -2px;
    text-align: center;
    width: 14px;
  }

  .meta-group {
    display: block;
    padding-left: 0rem !important;
    margin-bottom: 0rem !important;
  }
  
  .meta-group * {
    display: block;
    
  }
  
  .meta-data {
    color: #666;
    font-size: 13px;
    font-weight: 300;
  }
  
  .meta-data:not(:last-child)::after {
    content: "\00B7";
    margin: 0 5px;
  }