.CareNavigatorReports{
    padding: 10px 20px;
    /* width: 56%; */
    margin: 0 auto;
}

.comntent-CareNavigatorReports{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.CareNavigatorReports .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.CareNavigatorReports .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.CareNavigatorReports .ConfigurationSettings-title {
    font-size: 16px;
    font-weight: 600;
    color: #555555;
    margin-bottom: 0rem;
    text-align: left;
  }
  
.CareNavigatorReports .form-check-input {
    height: 22px;
    width: 22px;
    margin-right: 6px;
    border: 1px #DCDCDC solid !important;
}

.CareNavigatorReports .form-check-input:checked {
    background-color: #03335b !important;
    border-color: #03335b !important;
}

.CareNavigatorReports  label.col-form-label {
    font-weight: 400;
    color: #555555;
}