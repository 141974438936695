.table-container {
  width: 100%;
  overflow-x: auto;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th, .table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.table th {
  background-color: #e9e9e9;
}
.colored {
  background-color: #dcfffb !important;
}
.nav-tabs {
  border: 0px;
}
.card-task-appoint {
  background-color: #fff;
  width: 100%;
  overflow: visible;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  --bs-card-border-width: 0;
  --bs-card-border-color: transparent;
  --bs-card-border-radius: 0;
  --bs-card-box-shadow: none;
  box-shadow: none;
}
.card-task-appoint .card-title {
  font-size: 20px;
  color: #333;
  font-weight: 500;
  fill: #333;
}
.card-task-appoint .card-subtitle {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  fill: #333;
}
.card-task-appoint .addServices-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 15px !important;
  padding: 8px 14px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}
.card-task-appoint .addServices-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .bg-td-green {
  background-color: #dcfffb;
}
.card-task-appoint .txt-done {
  color: #03335b;
}
.card-task-appoint .txt-green {
  color: #2dbf02;
}
.card-task-appoint .txt-grey {
  color: #646464;
}
.card-task-appoint .txt-red {
  color: #f00;
}
.card-task-appoint .txt-blue {
  color: #05f;
}
.card-task-appoint .txt-yellow {
  color: #ffc400;
}
.card-task-appoint .complated-btn {
  color: #333 !important;
  background: #00f6ff !important;
  border: 1px solid #00f6ff !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task-appoint .complated-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .bookTest-btn {
  color: #fff !important;
  background: #7ec962 !important;
  border: 1px solid #7ec962 !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task-appoint .bookTest-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .Booked-btn {
  color: #fff !important;
  background: #578fff !important;
  border: 1px solid #578fff !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task-appoint .Booked-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .viewNotes-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task-appoint .viewNotes-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .setTarget-btn {
  color: #fff !important;
  background: #37c504 !important;
  border: 1px solid #37c504 !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task-appoint .setTarget-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task-appoint .cross-btn-close {
  background-color: #acacac;
  padding: 7px;
  color: #fff !important;
  border-radius: 20px;
  font-size: 10px;
}
.messageDiv {
  color: #71bc78;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #0f0;
}
.errorDiv {
  color: #fb6180;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #f00;
}
.required {
  color: #f00;
}
