.CreateNewTemplate{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.CreateNewTemplate .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.create-new-template{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    /* margin-bottom: 4rem !important; */
    padding: 10px;
    width: 100%;
}

.create-new-template .boxbg-card{
    background-color: #F5F5F5;
    border-radius: 14px;
    padding: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.create-new-template .boxbg-card.active{
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border: 1px solid #c0c0c0;
}

.create-new-template .card-icon-tick {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.create-new-template .card-images {
    /* height: 100px; */
    margin-bottom: 1rem;
    border-radius: 10px;
}
  
.create-new-template .card-title {
  font-size:42px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
}

.create-new-template .card-text {
  font-size: 15px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.create-new-template .last-sync {
    color: #636773;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 0px;
}

.create-new-template .last-sync span{
    font-weight: 600;
}

.create-new-template .add-new-template-title {
    color: #212121;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: .5rem;
}

.create-new-template .add-language{
    background-color: #ceebff;
    border: 1px dashed #1279be !important;
    border-radius: 10px !important;
    padding: 4px 10px;
}

.create-new-template .add-new-template-subtitle {
    color: #212121;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: .5rem;
}

.create-new-template .add-new-template-subtitle1 {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .5rem;
}

.create-new-template .form-check-input:checked {
    background-color: #03335b !important;
    border-color: #03335b !important;
}

.create-new-template .form-check-input {
    border: 1px solid #dcdcdc !important;
    height: 22px;
    margin-right: 6px;
    width: 22px;
}

.create-new-template .ql-container.ql-snow {
    height: 117px !important;
}

.create-new-template .copy-code-box{
    background-color: #F2F7F9;
    border-radius: 8px;
    padding: 16px;
}

.create-new-template .copy-code-alt-box{
    background-color: #FFFFFF ;
    border: 0.5px dashed #707070;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-new-template .copy-code-title {
    color: #464646;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: .5rem;
}

.create-new-template .iphone{
    width:240px;
    height:450px;
    /*background-image:url('https://storage.googleapis.com/ksabupatest/2024/09/24/tag93/h4khhs73ra.png');*/
    background-image:url('https://storage.googleapis.com/ksabupatest/2024/10/20/t0j2b/dh3og9z4z8.png');
    background-size:100% 100%;
    margin:0 auto;
    position:relative;
}
  
.create-new-template .border{
    position: absolute;
    top: 20%;
    right: 10%;
    left: 10%;
    bottom: 20%;
    overflow-x: hidden;
    border: 0 !important;
    overflow-y: scroll;
}

.create-new-template .quate-bg{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 10px;
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: visible;

}

.create-new-template .quate-title {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.create-new-template .quate-subtitle {
    color: #858585;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.create-new-template .quate-time {
    color: #858585;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0rem;
    text-align: right;
}


.limited-inputs {
    position: absolute;
    right: 16px;
    color: rgb(152, 152, 152);
    font-size: 16px;
    font-weight: 400;
}

.limited-inputs1 {
    position: absolute;
    right: 16px;
    color: rgb(152, 152, 152);
    bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}
.file-input-container{
    width: 50%;
}
.custom-file-input {
    display: none;
}
.custom-file-label {
    cursor: pointer;
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 16px !important;
    padding: 8px 20px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
.custom-file-label:hover {
    color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.image-preview {
    margin-top: 0px;
}
.image-preview img {
    max-width: 100%;
    max-height: 100px; 
    border: 1px solid #ddd;
    border-radius: 8px;
}

.delete-preview-btn {
    color: #03335b !important;
    background: transparent !important;
    border: 1px solid #DCDCDC !important;
    font-size: 14px !important;
    padding: 5px 7px !important;
    border-radius: 28px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
}
.delete-preview-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}

/* .tooltip > div.tooltip-inner {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #4e4e4e !important;
  }
  
  .tooltip.show {
    opacity: 1 !important;
  }
  
  .tooltip > div.arrow::before {
    border-bottom-color: #fff !important;
    color: #000 !important;
  } */

  .rmsc-2 {
    --rmsc-h: 19px !important;
}
.select-input-ui-select-2 {
    padding: 3px 4px !important;
}


.select-input-ui-2 {
    border: 1px solid #d8d8d8 !important;
    border-radius: 14px !important;
    color: #343434 !important;
    font-size: 16px !important;
    background-color: #fdfdfd !important;
    padding: 8px 12px !important;
    width: 100% !important;
}
.form-select-2 {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* Base styles for the editor */
.ql-editor {
  text-align: initial;
}

/* Styles for Arabic paragraphs */
.ql-editor p[lang="ar"] {
  text-align: right;
  direction: rtl;
}

/* Styles for English paragraphs */
.ql-editor p[lang="en"] {
  text-align: left;
  direction: ltr;
}

/* Handle nested elements */
.ql-editor p[lang="ar"] * {
  direction: rtl;
}

.ql-editor p[lang="en"] * {
  direction: ltr;
}

/* Optional: Style for the editor when typing in Arabic */
.ql-editor:has(p[lang="ar"]:focus) {
  direction: rtl;
}

/* Optional: Style for the editor when typing in English */
.ql-editor:has(p[lang="en"]:focus) {
  direction: ltr;
}