

.corporate-profile-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    margin-bottom: 0.5rem;
    border: 1px solid #e9e9e9;
    object-fit: contain;
}

.corporate-profile-imageContainer {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0rem;
    text-align: center;
}
.corporate-profile-imageContainer  a {
        text-decoration: none;
        color: #333;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1;
        margin-bottom: 0rem;
    }
.corporate-profile-img-title {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}