.ReportsPrograms{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.reports-programs{
  background-color: #fff;
  color: #000;
  padding: 14px;
  border-radius: 14px;
  /* height: 75vh; */
  display: block;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
 
}

.reports-programs::-webkit-scrollbar{
    display: none;
}

.ReportsPrograms .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

  .table-bg{
    background: #F1F1F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
  }

.table-bg .table>:not(caption)>*>* {
    background-color: #F1F1F2 !important;
    color: #636773;
}

.table-bg thead {
    border-bottom: 1px solid #707070;
}

.table-icon{
    height: 20px;
    width: 20px;;
}



.reports-programs .bg-grey {
  background-color: #F3F6FD;
}

.reports-programs .bg-lightgreen {
  background-color: #e6faec;
}

.reports-programs .bg-lightblue {
  background-color: #f0f4ff;
}

.reports-programs .bg-lightred {
  background-color: #ffeef0;
}

.reports-programs .card-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.reports-programs .card-title {
  font-size:42px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
}

.reports-programs .card-text {
  font-size: 15px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: left;
}

.pathways-table .table-dark {
  --bs-table-color: #333;
  --bs-table-bg: #D4E6EF;
  --bs-table-border-color: transparent;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #333;
}

.pathways-table td a {
  text-decoration: none;
  color: #007bff;
  text-decoration: underline;
}

.pathways-table td img {
  width: auto;
  height: 20px;
}

.pathways-table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #FBFBFB;
  --bs-table-border-color: #E9E9E9;
}

.pathways-table .table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #fff;
  --bs-table-border-color: #E9E9E9;
}

.appointments-table .table-dark {
  --bs-table-color: #333;
  --bs-table-bg: #C5C1C1;
  --bs-table-border-color: transparent;
  color: #333;
  font-size: 14px;
  font-weight: 300;
}

.appointments-table .text-Completed{
  color: #01CD88 !important;
  font-weight: 400;
  background-color: #FAFAFA;
}

.appointments-table .text-Missed{
  color: #CB0000;
  font-weight: 400;
  background-color: #FAFAFA;
}
.appointments-table .text-Scheduled{
  color: #4D4D4D;
  font-weight: 400;
  background-color: #FAFAFA;
}

.appointments-table tr {
  font-size: 14px;
  border-color: #fff;
}
.appointments-table .bg-appointments1{
  --bs-table-border-color: #F5EAF1 !important;
  background-color: #F5EAF1;
  color: #343434;
}
.appointments-table .bg-appointments2{
  --bs-table-border-color: #ECFBFE!important;
  background-color: #ECFBFE;
  color: #343434;
}

.sticky-top-bar{
  position: sticky !important;
  top: 56px;
  background-color: #f1f5fc;
  z-index: 2;
  /* padding-bottom: 10px; */
}


.kzZBXj {
  overflow: visible;
  white-space: pre-line !important;
  text-overflow: inherit !important;
}