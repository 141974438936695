.mdp-table-table th{
    background-color: rgb(212, 230, 239);
    font-weight: 500;
    font-size: 14px;
}


.mdp-table-table td{
    vertical-align: middle;
    border: 0px solid #efefef;
}