.hide {
    display: none !important;
}

.messageDiv {
    background-color: #71BC78;
    color: #000;
    padding: 10px;
    margin: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #00ff00;
}

.errorDiv {
    /*background-color: #FB6180;*/
    color: #000;
    padding: 10px;
    margin: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #ff0000;
}

.selectedSlot {
    background-color: #1279be;
    color: #fff;
}

#wpnb .modal-body .tab-content {
    width: 100%;
    text-align: left;
    border-left: 1px solid #B1B1B1 !important;
    border-bottom: 1px solid #B1B1B1 !important;
    border-right: 1px solid #B1B1B1 !important;
    padding: 0px !important;
}

#wpnb .modal-body .tab-content .tab-pane div .tab-content {
    width: 100%;
    text-align: left;
    border-left: 0px solid #B1B1B1 !important;
    border-bottom: 0px solid #B1B1B1 !important;
    border-right: 0px solid #B1B1B1 !important;
}

div#uncontrolled-tab-example-tabpane-workingPlans {
    border-top: 1px solid #B1B1B1 !important;
    margin-top: -1px;
}

div#uncontrolled-tab-example-tabpane-breaks {
    border-top: 1px solid #B1B1B1 !important;
    margin-top: -1px;
}

.workingPlans .nav-link {
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-size: 22px;
    font-weight: 500;
    color: #B1B1B1;
    padding: 10px 30px;
    border-bottom: 1px solid #B1B1B1;
}

.workingPlans .nav-link.active {
    color: #333;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: #B1B1B1;
    border-bottom: 0;
}

div#uncontrolled-tab-example-tabpane-workingPlans div ul.nav.nav-tabs {
    display: flex !important;
    justify-content: space-evenly !important;
    margin-bottom: 1rem;
}

div#uncontrolled-tab-example-tabpane-workingPlans div ul.nav.nav-tabs .nav-link {
    border: 0;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-size: 18px;
    font-weight: 500;
    color: #B1B1B1;
    padding: 8px;
    border-bottom: 0px solid #B1B1B1;
}

div#uncontrolled-tab-example-tabpane-workingPlans div ul.nav.nav-tabs .nav-link.active {
    color: #333;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: #7D7D7D;
    border: 0;
    border-bottom: 3px solid #7D7D7D;
}


.time-capsule {
    font-size: 12px;
    padding: 5px 12px;
    border: 1px solid;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.day-hour-h5 {
    text-align: center;
    font-size: 16px;
    color: #333;
    margin-bottom: 1rem;
}


.copy-cell {
    display: flex;
    align-items: center;
    gap: 10px;
}

.align-right {
    text-align: right;
}

.mr-10 {
    margin-right: 10px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pad-10 {
    padding: 10px;
}


.control-input-uibox {
    border: 1px solid #BBBBBB !important;
    border-radius: 14px !important;
    color: #343434 !important;
    font-size: 18px !important;
    background-color: #fff !important;
    padding: 6px 12px !important;
    width: 100%;
}

.copy-save-btn {
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 16px !important;
    padding: 10px 24px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}

.copy-save-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}


.close-reset-btn {
    color: #828282 !important;
    background: #fff !important;
    border: 1px solid #fff !important;
    font-size: 16px !important;
    padding: 10px 24px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}

.close-reset-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}