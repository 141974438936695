.MDPServiceProvider{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.MDPServiceProvider .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.MDP-Service-Provider{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.MDP-Service-Provider .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}
