@media print {
    @page {
        size: A4;
        margin: 0;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
        /* padding: 20px; */
    }
}
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;
    }
    .header_div {
        border-bottom: 1px solid #ccc;
        margin-bottom: 28px;
        padding-bottom: 10px;
    }
    .title {
        font-size: 32px;
        color: #fa9d75;
        font-weight: bold;
    }
    .patient_info {
        font-size: 14px;
        color: #666;
    }
    .subtitle {
        font-size: 24px;
        color: #666;
        margin-bottom: 10px;
    }
    .congrats {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .vital_params {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .vital_desc {
        font-size: 16px;
        margin-bottom: 28px;
    }
    .comorbidities {
        background-color: #f0f0f0;
        padding: 10px;
        margin-bottom: 60px;
        width: 60%;
    }
    .comorbidities_title {
        font-weight: bold;
        margin-bottom: 5px;
    }
    .comorbidities_desc {
        font-size: 12px;
        font-style: italic;
    }
    .body_diagram {
        position: relative;
        height: 540px;
    }
    .body_image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
    }
    .body_image img {
        height: 100%;
    }
    .parameter {
        position: absolute;
        width: 250px;
    }
    .parameter img {
        width: 80px;
        /* height: 40px; */
        margin-bottom: 5px;
    }
    .parameter_left {
        left: 0;
        text-align: left;
    }
    .parameter_right {
        right: 0;
        text-align: right;
    }
    .parameter_left img {
        float: left;
        margin-right: 10px;
    }
    .parameter_right img {
        float: right;
        margin-left: 10px;
    }
    .parameter_title {
        font-weight: bold;
        /* margin-top: px; */
        margin-bottom: 5px;
        font-size: 14px;
    }
    .parameter_value {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .parameter_status {
        font-size: 12px;
        color: #4CAF50;
    }
    .parameter_status.concern {
        color: #FFA500;
    }
    .parameter-status.not_taken {
        color: #999;
    }