.sidebar{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #f1f5fc;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open{
  width: 250px;
}
.sidebar .logo-details{
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon{
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name{
  opacity: 1;
}
.sidebar .logo-details #btn{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn{
  text-align: right;
}
.sidebar i{
  color: #8d9eb5;
  height: 50px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list i{
  color: #03335b;
}
.sidebar .nav-list {
  margin-top: 20px;
  /* height: 84%; */
  padding-left: 0rem;
  /* overflow-y: hidden;  */
  transition: overflow-y 0.3s ease; /* Optional: smooth transition */
  /* overflow-x:hidden; */
}
/* 
.sidebar .nav-list:hover {
  overflow-y: scroll;
} */
.sidebar li{
  position: relative;
  margin:5px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #1279be;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
  color: #FFF;
}
.sidebar li:hover .tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip{
  display: none;
}
.sidebar input{
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1279BE;
}
.sidebar.open input{
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1279BE;
  color: #FFF;
}
.sidebar.open .bx-search:hover{
  background: #1279BE;
  color: #FFF;
}
.sidebar .bx-search:hover{
  background: #FFF;
  color: #1279BE;
}
.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #fff;
}
.sidebar li a:hover{
  background: rgb(18 121 190 / 20%);
  color: #333;
}

.sidebar li a.active{
  background: rgb(18 121 190 / 20%);
  color: #fff;
}
.sidebar li a.active .links_name {
  color: #333;
}

.sidebar li a.active i {
    color: #333;
}
.sidebar li a .links_name{
  color: #61748f;
  font-size: 15px;
  font-weight: 500;
  /* white-space: nowrap; */
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  line-height: 1.2;
}
.sidebar.open li a .links_name{
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
  color: #333;
}
.sidebar li i{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile{
  position: fixed;
  height: 60px;
  width: 50px;
  /* left: 0; */
  bottom: -20px;
  padding: 10px 14px;
  background: #fff;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile{
  width: 220px;
}
.sidebar li .profile-details{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job{
  font-size: 15px;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
  padding-left: 32px;
}
.sidebar li.profile .job{
  font-size: 12px;
}
.sidebar .profile #log_out{
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  background: #fff;
  color: #000;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 0px;
  /* transition: all 0.5s ease; */
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}
.home-section{
  position: relative;
  background: #f1f5fc;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  /* z-index: 2; */
}

.home-section-new{
  position: relative;
  background: #f1f5fc;
  min-height: 100vh;
  top: 0;
  left: 0px;
  width: 100%;
  transition: all 0.5s ease;
  padding: 10px;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}
@media (max-width: 420px) {
  .sidebar li .tooltip{
    display: none;
  }
}