/* IntegrationDashboard.css */
.custom-tabs {
    font-family: inherit;
  }
  
  .custom-tab {
    border: none;
    background: rgb(233, 233, 233);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .custom-tab:focus {
    outline: none;
  }
/*   
  .active-tab {
    color: white !important;
    background-color: #03335b !important;
    border-color: #03335b !important;
  }
  
  .react-tabs__tab-list {
    border-bottom: none !important;
  }
  
  .react-tabs__tab--selected {
    background: #03335b !important;
    border-color: #03335b !important;
    color: white !important;
  }
  
  .react-tabs__tab--selected a {
    color: white !important;
  } */