.ContentChallenges{
    padding: 10px 20px;
}

.content-challenges{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* margin-bottom: 4rem !important; */
}

.content-challenges::-webkit-scrollbar{
    display: none;
}

.content-challenges .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.content-challenges .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}
  .table-bg{
    background: #F1F1F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
  }

.table-bg .table>:not(caption)>*>* {
    background-color: #F1F1F2 !important;
    color: #636773;
}

.table-bg thead {
    border-bottom: 1px solid #707070;
}

.table-icon{
    height: 20px;
    width: 20px;;
}

.content-challenges .bg-challenges-1 {
  background-color: #fee9e6;
}

.content-challenges .bg-challenges-2 {
    background-color: #fff8ce;
}

.content-challenges .bg-challenges-3 {
    background-color: #c4f2ff;
}
  
.content-challenges .bg-challenges-4 {
    background-color: #cae1b3;
}
  
.content-challenges .bg-challenges-5 {
    background-color: #dfc4bb;
}

.content-challenges .card-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.content-challenges .card-title {
  font-size: 20px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
}

.content-challenges .card-text {
  font-size: 14px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.sticky-top-bar{
  position: sticky !important;
  top: 56px;
  background-color: #f1f5fc;
  z-index: 2;
  /* padding-bottom: 10px; */
}

.challenges-title {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.3rem;
    text-align: center;
}

.challenges-box {
    background-color: #fff;
    border: 0 solid #e9e9e9;
    border-radius: 16px;
    box-shadow: 0 2px 6px #0000000f;
    padding: 14px;
    text-align: center;
}

.challenge-running {
    background-image: linear-gradient(135deg, #cee0b2 10%, #cee0b2);
}
.challenge-ended {
    background-image: linear-gradient(135deg, #ffeae9 10%, #ffebd9);
}
.challenge-logo {
    height: 50px;
    width: 50px;
}

.challengeTitle {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.3rem;
}
.challengeSubTitle {
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin-bottom: 0.3rem;
}
.challengeSubTitle span{ font-weight: 600;}

.small-btn {
    display: inline;
    font-size: 12px;
    margin: 2px;
}

.swiper-pagination-bullet-active {
    background: rgba(0, 0, 0, 1) !important;
}

.swiper-pagination {
    bottom: -2px !important;
}