
.PathNotResolved{
    background-color: #F0F0F2;
    padding: 20px;
    height: 100vh;
    width: 100%;
  }
  
  
  .PathNotResolved .row{
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      height: 100%;
  }
  
  .PathNotResolved .logo-ksa{
      height: 82px;
  }
  
  .PathNotResolved .title{
      font-size: 30px;    
      color: #656565;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
  }
  
  .banner-img-noaccess{
      height: 300px;
  }
  
  .powered-by-goqii{
      width: 100%;
      text-align: right;
  }
  