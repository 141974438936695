@media print {
    @page {
        size: A4;
        margin: 0;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
    }
}
    .container {
        padding: 30px;
    }
    .header {
        background-color: #009ea7;
        color: white;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo {
        height: 30px;
    }
    .header_text {
        font-size: 18px;
        font-weight: bold;
    }
    h1 {
        color: #179aa1;
        font-size: 32px;
        margin-bottom: 5px;
        font-weight: 500;
    }
    h2 {
        color: #666;
        font-size: 18px;
        font-weight: normal;
        margin-top: 0;
    }
    .section {
        margin: 30px 0;
    }
    .section_title {
        color: #0a9aa2;
        font-size: 24px;
        margin-bottom: 5px;
        font-weight: 500;
    }
    .section_content {
        font-size: 14px;
        line-height: 1.4;
        margin-left: 35px;
    }
    .disclaimer {
        font-size: 12px;
        color: #666;
        column-count: 2;
        column-gap: 20px;
    }
    .disclaimer_title {
        font-size: 12px;
        color: #666;
        column-count: 2;
        column-gap: 20px;
    }
    .disclaimer ul {
        padding-left: 20px;
        margin: 0;
    }