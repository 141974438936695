.outerLayout {
    display: flex;
    justify-content: center;
}

.custom_textarea {
    box-sizing: border-box;
    /* width: 320px; */
    max-width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 5px;
    color: #1C2025;
    background: #fff;
    border: 1px solid #B0B8C4;
    box-shadow: 0px 2px 2px #F3F6F9;
}

.custom_textarea:hover {
    border-color: '#007FFF';
  }

  .custom_textarea:focus {
    outline: 0;
    border-color: '#007FFF';
    box-shadow: 0 0 0 1px #007FFF;
  }