.MDPAddNewService{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.MDPAddNewService .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.MDP-Add-New-Service{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.MDP-Add-New-Service .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.MDP-Add-New-Service .table-dark {
    --bs-table-color: #333;
    --bs-table-bg: #D4E6EF;
    --bs-table-border-color: transparent;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #333;
  }
  
  .MDP-Add-New-Service td a {
    text-decoration: none;
    color: #007bff;
    text-decoration: underline;
  }
  
  .MDP-Add-New-Service td img {
    width: auto;
    height: 100px;
  }
  
  .MDP-Add-New-Service .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #FBFBFB;
    --bs-table-border-color: #E9E9E9;
    vertical-align: middle;
  }
  
  .MDP-Add-New-Service .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-bg-type: #fff;
    --bs-table-border-color: #E9E9E9;
    vertical-align: middle;
  }


  .MDP-Add-New-Service .table th {
    background-color: #D4E6EF !important;
    }
