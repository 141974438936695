.CareNavigatorPerformance{
    padding: 10px 20px;
}

.content-CareNavigatorPerformance{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* margin-bottom: 4rem !important; */
}

.content-CareNavigatorPerformance::-webkit-scrollbar{
    display: none;
}

.content-CareNavigatorPerformance .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

  .table-bg{
    background: #F1F1F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
  }

.table-bg .table>:not(caption)>*>* {
    background-color: #F1F1F2 !important;
    color: #636773;
}

.table-bg thead {
    border-bottom: 1px solid #707070;
}

.table-icon{
    height: 20px;
    width: 20px;;
}


.content-CareNavigatorPerformance .bg-lightgreen {
  background-color: #e6faec;
}

.content-CareNavigatorPerformance .bg-lightblue {
  background-color: #f0f4ff;
}

.content-CareNavigatorPerformance .bg-lightred {
  background-color: #ffeef0;
}

.content-CareNavigatorPerformance .card-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.content-CareNavigatorPerformance .card-title {
  font-size: 22px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
}

.content-CareNavigatorPerformance .card-text {
  font-size: 14px;
  color: #3A6FFF;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.CareNavigatorPerformance-table .table-CareNavigatorPerformance {
  --bs-table-color: #333;
  --bs-table-bg: #D4E6EF;
  --bs-table-border-color: transparent;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #333;
  text-align: center;
}

.CareNavigatorPerformance-table tr th{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.CareNavigatorPerformance-table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #FBFBFB;
  --bs-table-border-color: #E9E9E9;
  text-align: center;
}

.CareNavigatorPerformance-table .table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #fff;
  --bs-table-border-color: #E9E9E9;
  text-align: center;
}

.sticky-top-bar{
  position: sticky !important;
  top: 56px;
  background-color: #f1f5fc;
  z-index: 2;
  /* padding-bottom: 10px; */
}

.CareNavigatorPerformance-main-title {
    font-size: 20px;
    color: #636773;
    font-weight: 500;
    margin-bottom: 10px;
}

.CareNavigator-wise-table .table-CareNavigatorPerformance {
    --bs-table-color: #333;
    --bs-table-bg: #E8F8F7;
    --bs-table-border-color: transparent;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #333;
    text-align: center;
  }
  
  
  .CareNavigator-wise-table  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #FBFBFB;
    --bs-table-border-color: #E9E9E9;
    text-align: center;
  }
  
  .CareNavigator-wise-table  .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-bg-type: #fff;
    --bs-table-border-color: #E9E9E9;
    text-align: center;
  }
  
  .CareNavigator-wise-table tr th{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
 } 
 
.bg-cn-blue{
    background-color: #E8F8F7;
    display: block;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
 }

 .form-check-box{
  border: 1px solid #DCDCDC !important;
  padding: 8px !important;
 }

 .form-check-box:checked {
  background-color: #03335b !important;
  border-color: #03335b !important;
}

.content-CareNavigatorPerformance .card-columns {
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}

.content-CareNavigatorPerformance .card-columns .card {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
}

.content-CareNavigatorPerformance .card-columns .card:nth-child(odd) {
  order: 1;
  display: inline-block;
}

.content-CareNavigatorPerformance .card-columns .card:nth-child(even) {
  order: 2;
  display: inline-block;
}

.content-CareNavigatorPerformance .card-columns .card .main-title {
  font-size: 20px;
  color: #636773;
  font-weight: 500;
  margin-bottom: 10px;
}

.content-CareNavigatorPerformance .card-columns .card .main-subtitle {
  font-size: 26px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  margin-bottom: 0;
}

.content-CareNavigatorPerformance .card-columns .card .main-subtitle i{
  color: #19A849;
  font-size: 22px;
  font-weight: 600;
}

.content-CareNavigatorPerformance .card-columns .card .main-content {
  font-size: 16px;
  color: #636773;
  font-weight: 500;
  margin-bottom: 10px;
}

.content-CareNavigatorPerformance .card-columns .card .main-box {
  border: 0.5px solid #707070;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.content-CareNavigatorPerformance .card-columns .card .graph-box {
  border-radius: 8px;
  position: absolute;
  height: 100%;
  z-index: 0;
}

.bg-graph-pink{
  background-color: #F7EFFF;
}

.bg-graph-grey{
  background-color: #F5F5F5;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph {
  font-size: 15px;
  color: #4a4e5c;
  font-weight: 500;
  margin-bottom: 0px;
  z-index: 1;
  padding: 10px;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph i.bi-arrow-up{
  color: #19A849;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph i.bi-arrow-down{
  color: #FF0000;
}
.content-CareNavigatorPerformance .card-columns .card .main-box1 {
  border: 0.5px solid #707070;
  border-radius: 8px;
  /* padding: 10px; */
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 86px;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph1 {
  font-size: 18px;
  color: #636773;
  font-weight: 600;
  margin-bottom: 0px;
  z-index: 1;
  padding: 0px 10px 10px 10px;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph1 i.bi-arrow-up {
  color: #19A849;
}

.content-CareNavigatorPerformance .card-columns .card .text-graph1 i.bi-arrow-down {
  color: #FF0000;
}

.DetailedCareNavigatorPerformanceTable .dBiUCQ:nth-child(1), .DetailedCareNavigatorPerformanceTable .gdBOih:nth-child(1) {
  min-width: 200px !important;
}
.DetailedCareNavigatorPerformanceTable .dBiUCQ:nth-child(2), .DetailedCareNavigatorPerformanceTable .gdBOih:nth-child(2) {
  min-width: 300px !important;
}
.DetailedCareNavigatorPerformanceTable .dBiUCQ, .DetailedCareNavigatorPerformanceTable .gdBOih {
  min-width: 140px !important;
}