
.footer{
    position: fixed;
    bottom: 0px;
    background-color: #f1f5fc;
    padding: 10px;
    width: 100%;
    transition: all 0.5s ease;
    z-index: 100;
}

.sidebar.open ~ .home-section .footer{
    position: fixed;
    bottom: 0px;
    background-color: #f1f5fc;
    padding: 10px;
    left: 250px;
    width: calc(100% - 250px);
    transition: all 0.5s ease;
}

.home-section .footer{
    position: fixed;
    bottom: 0px;
    background-color: #f1f5fc;
    padding: 10px;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
}

.footer-right{
    color: #636773;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    margin-bottom: 0px;
}