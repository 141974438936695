#medicationForm input[type=text] {
  /* // margin: 10px; */
}

#medicationForm select {
  /* // margin: 10px; */
}

.rmsc .dropdown-container {
  border:0px solid var(--rmsc-border) !important;
}

.select-input-ui-select{
padding: 3px 4px !important;}

.css-13cymwt-control{
  border-style: none !important
}
.css-1u9des2-indicatorSeparator{
  display: none !important;
}