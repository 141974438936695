.messageDiv {
    background-color: #71bc78;
    color: #000;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #0f0;
}
.errorDiv {
    background-color: #fb6180;
    color: #000;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #f00;
}
.hide {
    display: none !important;
}
#hrasmoking input[type="radio"] {
    margin: 10px;
}
#hrasmoking input[type="checkbox"] {
   /* // margin: 10px;
    */
}
#hrasmoking input[type="text"] {
    margin-bottom: 10px;
}
.specify {
    width: 40% !important;
}
.question {
    margin-bottom: 10px;
}
.card-hra {
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    --bs-card-border-width: 0;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0;
    --bs-card-box-shadow: none;
    box-shadow: none;
}
.card-hra .card-title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    fill: #333;
}
.card-hra .card-subtitle {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    fill: #333;
}
.card-hra .addServices-btn {
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 15px !important;
    padding: 8px 14px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
}
.card-hra .addServices-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .bg-td-green {
    background-color: #dcfffb;
}
.card-hra .txt-done {
    color: #03335b;
}
.card-hra .txt-green {
    color: #2dbf02;
}
.card-hra .txt-red {
    color: #f00;
}
.card-hra .txt-blue {
    color: #05f;
}
.card-hra .txt-yellow {
    color: #ffc400;
}
.card-hra .complated-btn {
    color: #333 !important;
    background: #00f6ff !important;
    border: 1px solid #00f6ff !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}
.card-hra .complated-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .bookTest-btn {
    color: #fff !important;
    background: #7ec962 !important;
    border: 1px solid #7ec962 !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}
.card-hra .bookTest-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .Booked-btn {
    color: #fff !important;
    background: #578fff !important;
    border: 1px solid #578fff !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}
.card-hra .Booked-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .viewNotes-btn {
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}
.card-hra .viewNotes-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .setTarget-btn {
    color: #fff !important;
    background: #37c504 !important;
    border: 1px solid #37c504 !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}
.card-hra .setTarget-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}
.card-hra .cross-btn-close {
    background-color: #acacac;
    padding: 7px;
    color: #fff !important;
    border-radius: 20px;
    font-size: 10px;
}
