.HomeScreen{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.HomeScreen .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.home-screen{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}

.sticky-top-bar {
    background-color: #f1f5fc;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 56px;
    z-index: 2;
}